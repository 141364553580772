.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
  
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  /* background: #f8df00; */
  border-bottom: 3px solid #E66931;
  /* border-color: #292929; */
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 2.2px solid #E66931;
  background: transparent;
  padding: 0.9rem;
  border-radius: 1em 1em 1em 1em;
  font-size: 1.1rem;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #ffffff;
  color : rgba(0, 60, 134, 1);
}

.nav-links button:hover{
  color : #E66931  ;
}




/*================== FULL RESPONSIVE MODE ============*/

@media (min-width: 576px) {
}

@media (min-width: 768px) { 

  .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;

  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
    
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    border-bottom: 3px solid #E66931;
    color: #292929;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 2.2px solid #E66931;
    background: transparent;
    padding: 0.9rem;
    border-radius: 1em 1em 1em 1em;
    font-size: 1.1rem;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #ffffff;
    color : rgba(0, 60, 134, 1);
  }
  
  .nav-links button:hover{
    color : #E66931;
  }
}


@media (min-width: 992px) {
  .nav-links {
    flex-direction: row;
  }
.NavLinks__call-us-button-link{
  width: 5em;
}

  .nav-links li {
    margin: 0rem;
  }

  .nav-links button {
    margin-left: 1em;
    padding: .5em;
    width: 6em;
  }
  .nav-links a {
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
    
  }

}


@media (min-width: 1200px) {
  .NavLinks__call-us-button-link{
    width: 10em; 
  }
  .nav-links li {
    margin-right: 1.1em;
  }

  .nav-links button {
    cursor: pointer;
    border: 2.2px solid #E66931;
    background: transparent;
    padding: 0.9rem;
    border-radius: 1em 1em 1em 1em;
    font-size: 1.1rem;
    margin-left: 2em;
    width: 100%;
    
  }
}


@media (min-width: 1400px) { 

  .nav-links li {
    margin-right: 2em;
    
  }
  .nav-links button {
    margin-left: 5em;
  }
}