
  

.SubscriptionPlan__subscription-plans-container{
    /* border: 3px solid red; */
}
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid blue; */
    }
    .SubscriptionPlan__subscription-card-component-container{
        /* border: 3px solid purple; */
    }
 
    .SubscriptionPlan__contact-card{
        /* border: 3px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SubscriptionPlan__card-contact-component-container{
        /* border: 3px solid red;    */
    }



@media (max-width: 575.98px) {
    
.SubscriptionPlan__main-section-container{
    padding: 1.3em;

}
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 2.5rem; 
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .SubscriptionPlan__subscription-card-component-container{
        margin-top: .5em;
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionPlan__main-section-container{
        padding: 1.3em;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__title{
        line-height: 2.5rem; 
    }


    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .SubscriptionPlan__subscription-card-component-container{
        margin-top: .5em;
        /* border: 3px solid purple; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionPlan__main-section-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: space-between;
        /* margin-left: 1.8em; */
        padding: 1em;
        /* border: 3px solid rgb(0, 0, 0); */
    }
    .SubscriptionPlan__main-section-container{
        /* border: 3px solid rgb(255, 0, 0); */
        display: flex;
    }
    .SubscriptionPlan__subscription-plans-container{
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid yellow; */
        width: 60%;
        padding-left: 1em;
        padding-right: 1em;
    }
    .SubscriptionPlan__subscription-card-component-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 40%;
        padding: .3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        /* border: 3px solid red; */
    }
        .SubscriptionPlan__subscription-description-component-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
         
        }
        .SubscriptionPlan__subscription-card-component-container{
            margin-left: 1em;
            margin-right: 1em; 
        }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionPlan__main-section-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        /* margin-left: 1.8em; */
        padding: 1em;
        /* border: 3px solid rgb(0, 0, 0); */
    }
    .SubscriptionPlan__main-section-container{
        /* border: 3px solid rgb(255, 0, 0); */
        display: flex;
    }
    .SubscriptionPlan__subscription-plans-container{
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid yellow; */
        width: 60%;
        padding-left: 1em;
        padding-right: 1em;
    }
    .SubscriptionPlan__subscription-card-component-container{
        /* border: 3px solid rgb(0, 0, 0); */
        width: 40%;
        padding: .3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
     
    }
}

@media (min-width: 1200px) {
    .SubscriptionPlan__subscription-plans-container{
        flex-direction: row;
        justify-content: center;
        align-content: center;
        padding: 1em;
    }

    .SubscriptionPlan__main-section-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SubscriptionPlan__subscription-plans-container{
        /* border: 5px solid blue; */
        display: flex;
        flex-direction: row;
    }
    .SubscriptionPlan__subscription-description-component-container{
        /* border: 3px solid yellow; */
        max-width: 60%;
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .SubscriptionPlan__subscription-card-component-container{
        /* border: 3px solid rgb(0, 0, 0); */
        max-width: 40%;
        padding: .3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    
}