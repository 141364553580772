


.Error404__about-container, .Error404__price-container,
.Error404__contact-container, .Error404__cancel-container{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 25%;
    height: 145px;
    position: relative;
    border-radius: 15px;
}
.Error404__about-container,
.Error404__contact-container,
.Error404__price-container,
.Error404__cancel-container{
    cursor: pointer;
    margin-left: .5em;
    margin-right: .5em;
    color: rgb(0, 0, 0);
}

.Error404__about-container:hover,
.Error404__contact-container:hover,
.Error404__price-container:hover,
.Error404__cancel-container:hover{
    cursor: pointer;
    margin-left: .5em;
    margin-right: .5em;
    color: #b20918;
}
    .Error404__about-text-container,
    .Error404__price-text-container,
    .Error404__contact-text-container,
    .Error404__cancel-text-container{
        position: absolute;
        top: 3em;   
        left: .5em;
        
        
    }
    .Error404__about-text-container h1, .Error404__price-text-container h1, 
    .Error404__contact-text-container h1, .Error404__cancel-text-container h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
    }
    .Error404__error-description h2{
        font-weight: 600;
    }


.Error404__pages-container{
    /* border: 3px solid red; */
    display: flex;
}
    .Error404__pages{
        /* border: 3px solid green; */
        display: flex;
    }

.Error404__contact-card-component-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;
    margin-bottom: 2.5em;
}





.Error404__error-container{
    /* border: 3px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.Error404__error-text-container{
    /* border: 3px solid green; */
    width: 45%;
}



.Error404__error-description{
    /* border: 3px solid green; */
}
.Error404__error-description p{
    /* border: 3px solid red; */
    width: 100%;
}

.Error404__error-title-conainter h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 7rem;
    text-align: center;
    color: #B20918;
    
}
.Error404__error-description p, h2 {
    font-size: 1.6rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;

    
}







/* =============== RESPONSIVE =========*/
@media (max-width: 575.98px) {
    .Error404__about-container, .Error404__price-container,
    .Error404__contact-container, .Error404__cancel-container{
        width: 70%;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: .6em;
        border-radius: 15px;
    }
    .Error404__pages-container{
        flex-direction: row;
        justify-content: center;
        
     }
         .Error404__pages{
             width: 100%;
             flex-direction: column;
             align-items: center;
         }
         .Error404__error-text-container{
            /* border: 3px solid green; */
            width: 100%;
            padding: 1.5em;
        }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .Error404__about-container, .Error404__price-container,
    .Error404__contact-container, .Error404__cancel-container{
        width: 35%;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
     
    }
    .Error404__pages-container{
        flex-direction: row;
        justify-content: center;
     }
         .Error404__pages{
             width: 100%;
             flex-direction: row;
             justify-content: space-around;
         }
         .Error404__error-text-container{
            /* border: 3px solid green; */
            width: 100%;
            padding: 1em;
        }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Error404__about-container, .Error404__price-container,
.Error404__contact-container, .Error404__cancel-container{
    width: 30%;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
 
}
    .Error404__pages-container{
        flex-direction: row;
        justify-content: center;
     }
         .Error404__pages{
             width: 100%;
             flex-direction: row;
             justify-content: space-around;
         }
         .Error404__error-text-container{
            /* border: 3px solid green; */
            width: 70%;
        }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    
    .Error404__pages-container{
        flex-direction: row;
        justify-content: center;
     }
         .Error404__pages{
             width: 100%;
             flex-direction: row;
             justify-content: space-around;
         }
         .Error404__error-text-container{
            /* border: 3px solid green; */
            width: 58%;
        }

}

@media (min-width: 1200px) {
.Error404__pages-container{
   flex-direction: row;
   justify-content: center;
}
    .Error404__pages{
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
    }
    


}