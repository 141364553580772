
.SectionDivider__section-container{
    /* border: 2px solid red; */
    background-color: #e66931;
    padding: .5em;
    /* border-radius: 0em 0em 1.25em 1.25em; */
}
    .SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
    }
        .SectionDivider__title-container{
            /* border: 3px solid blue; */
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            margin-bottom: 3.5em;

        }   
            .SectionDivider__title{
                /* border: 3px solid red; */
                color: #FFF;
                text-align: center;
                font-family: "Montserrat", sans-serif;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
            }
        .SectionDivider__icons-container{
                /* border: 3px solid rgb(255, 255, 255); */
                display: flex;
        }
            .SectionDivider__inner-icons-container p {
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: "Montserrat", sans-serif;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-top: 1em;
                /* border: 3px solid red; */
                            }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid purple; */
                display: flex;
                
            }
                .SectionDivider__icon-container{
                    /* border: 3px solid green; */
                    width: 4em;
                    
                }
                    .SectionDivider__icon{
                        /* border: 3px solid rgb(0, 0, 0); */
                        width: 100%;
                    }




@media (max-width: 575.98px) {
   .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        padding: 1em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: column;
        }
     .SectionDivider__text-container{
        /* border: 3px solid purple; */
        width: 100%;
     }

     .SectionDivider__inner-icons-container p {
        font-size: 1.1rem;
        font-weight: 400;
    }

       
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;

      
   }.SectionDivider__inner-container{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                
            }
            /*test*/
            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }

            /*end test*/
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
            }

            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
            }
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }
}

@media (min-width: 1200px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
            }

            .SectionDivider__inner-icons-container p {
                font-size: 1rem;
                font-weight: 400;
            }
            .SectionDivider__text-container{
                /* border: 3px solid red; */
                width: 70%;
            }
}